

































































import { Component, Prop } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import TasqJob from '../../interfaces/tasqs/TasqJob';
import { mixins } from 'vue-class-component';
import DataLoading from '../../lib/mixins/dataLoading';
import setpointModule from '@/store/modules/setpointModule';

@Component({
  components: {
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    LineChart: () => import('@/lib/charts/lineChart'),
  },
})
export default class SetpointFeedbackInfo extends mixins(DataLoading) {
  @Prop({ required: true }) tasq!: TasqJob;

  chartData = {
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'rgba(44,230,194,0.09)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: [],
        pointBorderColor: [],
        pointBackgroundColor: [],
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        datalabels: {
          display: [],
          align: 'left',
          backgroundColor: '#fff',
          borderRadius: 4,
          color: '#000750',
          offset: 12,
          padding: 6,
          formatter: {},
          clamp: true,
          clip: true,
        },
      },
      {
        label: '',
        data: [],
        borderColor: 'rgba(44,230,194,0.5)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        borderDash: [10, 15],
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        datalabels: {
          display: false,
        },
      },
      {
        label: '',
        data: [],
        borderColor: 'rgba(44,230,194,0.5)',
        backgroundColor: 'rgba(44,230,194,0.25)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        borderDash: [10, 15],
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        datalabels: {
          display: false,
        },
      },
    ],
    labels: [],
  };

  chartOptions = {};

  casing = [];

  get setPointData() {
    return setpointModule.tasqSetpointData;
  }

  async created() {
    this.startDataLoading();
    await this.changeChartTime();
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      events: ['click'],
      legend: {
        display: false,
      },
      animation: {
        duration: 500,
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#666A96',
            fontStyle: 'bold',
          },
          scaleLabel: {
            display: true,
            labelString: 'Tubing pressure',
            fontColor: '#fff',
          },
        }],
        yAxes: [
          {
            position: 'left',
            id: 'y-axis-0',
            gridLines: {
              color: 'rgba(102, 106, 150, 0.3)',
            },
            ticks: {
              fontColor: '#666A96',
              maxTicksLimit: 4,
              fontStyle: 'bold',
              min: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Median production per day',
              fontColor: '#fff',
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        displayColors: false,
        backgroundColor: '#fff',
        titleFontColor: '#8C8FB0',
        bodyFontColor: '#000750',
        bodyFontStyle: 'bold',
        titleAlign: 'center',
        bodyAlign: 'center',
        xPadding: 5,
        yPadding: 5,
        callbacks: {
          title: (tooltipItems) => `Median Production: ${this.setPointData.mainData[tooltipItems[0].index]}`,
          label: (tooltipItem) => [
            `Casing Pressure: ${this.setPointData.casing[tooltipItem.index] || 'N/A'}`,
            `Static Pressure: ${this.setPointData.static[tooltipItem.index] || 'N/A'}`,
            `Load Factor: ${this.setPointData.loadFactor[tooltipItem.index] || 'N/A'}`,
          ],
        },
      },
      plugins: {
        zoom: false,
      },
    };
    this.stopDataLoading();
  }

  async changeChartTime(value = 14) {
    await setpointModule.getSetpointDataForTasq({
      tasq: this.tasq,
      dataLength: value,
      wellName: this.tasq.wellName,
    });

    this.chartData.datasets[0].data = this.setPointData.mainData;
    this.chartData.datasets[0].pointRadius = this.setPointData.labels.map(
      // eslint-disable-next-line max-len
      (i, idx) => (idx === this.setPointData.currentPoint || idx === this.setPointData.recommendedPoint ? 5 : 0),
    );
    const points = this.setPointData.labels.map((i, idx) => {
      if (idx === this.setPointData.currentPoint) {
        return '#ffffff';
      }
      if (idx === this.setPointData.recommendedPoint) {
        return '#2CE6C2';
      }
      return 'transparent';
    });
    this.chartData.datasets[0].pointBorderColor = points;
    this.chartData.datasets[0].pointBackgroundColor = points;
    this.setPointData.labels.forEach(
      (i, idx) => {
        // @ts-ignore
        this.chartData.datasets[0].datalabels.display.push(
          idx === this.setPointData.currentPoint || idx === this.setPointData.recommendedPoint,
        );
      },
    );
    this.chartData.datasets[0].datalabels.formatter = (chartVal, context) => {
      if (context.dataIndex === this.setPointData.currentPoint) {
        return 'Current \nsetpoint';
      }
      if (context.dataIndex === this.setPointData.recommendedPoint) {
        return 'Recommended \nsetpoint';
      }
      return '';
    };
    this.chartData.datasets[1].data = this.setPointData.lowerData;
    this.chartData.datasets[2].data = this.setPointData.upperData;
    this.chartData.labels = this.setPointData.labels;

    setTimeout(() => {
      if (this.$refs.setpointChart) {
        (this.$refs.setpointChart as any).$data._chart.update();
      }
    }, 500);
  }
};
